import { AxiosResponse } from 'axios';
import useSWRImmutable from 'swr/immutable';

import { AddPlaylistsAPIModel } from '@/models/PlaylistInterface';
import PlaylistAPI from '@/network/PlaylistAPI';

import useAccount from '../useAccount';

const useSpotifyPlaylists = ({ spotifyId }: { spotifyId?: string }) => {
  const { unhurdAccountId } = useAccount();

  const { data, isLoading, mutate, error } = useSWRImmutable<AxiosResponse<AddPlaylistsAPIModel> | null>(
    () => {
      if (!unhurdAccountId || !spotifyId) return null;

      return `spotify-playlists-data-${unhurdAccountId}`;
    },
    async () => {
      if (!unhurdAccountId || !spotifyId) return null;

      return await PlaylistAPI.getUserSpotifyPlaylists({
        id: spotifyId,
      });
    }
  );

  return {
    allSpotifyPlaylists: data?.data.playlists,
    connectedSpotifyPlaylists: data?.data.playlists.filter((item) => item.isConnected),
    unconnectedSpotifyPlaylists: data?.data.playlists.filter((item) => !item.isConnected),
    spotifyPlaylistsIsLoading: isLoading,
    spotifyPlaylistsError: error,
    refetchSpotifyPlaylists: mutate,
  };
};

export default useSpotifyPlaylists;
